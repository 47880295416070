import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { generalTextData } from '../../general-fields/definitions/general-text'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeAddress = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalTextData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.ADDRESS,
        crmTag: CRM_TAGS.OTHER,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.ADDRESS}`),
      },
      props: { placeholder: t(`fieldTypes.${FormsFieldPreset.ADDRESS}`) },
      data: {
        placeholder: t(`fieldTypes.${FormsFieldPreset.ADDRESS}`),
      },
    },
  })
}

export const addressData: FieldData = {
  make: makeAddress,
  customFields: [],
  icon: iconNames.address,
  titleTranslationKey: `adi.${FormsFieldPreset.ADDRESS}FieldEditPanel.title`
}
