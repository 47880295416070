import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { FieldProperties, FieldData } from '../../field-types-data'
import translations from '../../../../services/translations'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'

const makeLinkToLogin = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      data: {
        text: t(`fieldTypes.${RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN}.text`),
      },
      props: {
        required: true,
      }
    },
  }
}

export const linkToLoginData: FieldData = {
  make: makeLinkToLogin,
  customFields: [],
  icon: iconNames.link,
  renderConfig: {
    [FormPlugin.REGISTRATION_FORM]: {
      isMandatory: true
    }
  },
  allowOnlyOnce: true,
  disableCollectionSync: true
}
