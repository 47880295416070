import { FormsFieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeGeneralRating = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.NUMBER,
    componentType: COMPONENT_TYPES.RATING,
    extraData: {
      role: FIELDS.ROLE_FIELD_RATING,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_RATING}`),
      },
      data: {
        titleText: t(`preset.${FormsFieldPreset.GENERAL_RATING}Title`),
        svgId: '503047d3d3884f6097b82dec7af52fa8.svg',
        labels: {
          '1': t(`preset.${FormsFieldPreset.GENERAL_RATING}FirstOption`),
          '2': t(`preset.${FormsFieldPreset.GENERAL_RATING}SecondOption`),
          '3': t(`preset.${FormsFieldPreset.GENERAL_RATING}ThirdOption`),
          '4': t(`preset.${FormsFieldPreset.GENERAL_RATING}FourthOption`),
          '5': t(`preset.${FormsFieldPreset.GENERAL_RATING}FifthOption`),
        },
      },
    },
  }
}

export const generalRatingData: FieldData = {
  make: makeGeneralRating,
  customFields: [customTypes.TEXT],
  icon: iconNames.rating,
}
