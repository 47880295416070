import * as _ from 'lodash'
import { FormPlugin } from '../../../constants/plugins'
import { ComponentConnection, Plugin } from '../api-types'

export const getActivePlugin = (plugins: FormPlugin[]): FormPlugin => {
  return _.first(plugins) || FormPlugin.FORM_BUILDER
}

export const getPlugins = (componentConnection: ComponentConnection): FormPlugin[] =>
  convertPluginsToFormsPlugins(_.get(componentConnection.config, 'plugins', []))

export const convertPluginsToFormsPlugins = (plugins: Plugin[] = []) => _.map(plugins, 'id')
