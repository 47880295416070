import { FieldsLayoutConfig, LABEL_MARGIN, LABEL_PADDING, TEXT_PADDING, TEXT_ALIGNMENT } from './constants/field-config'
import { COMPONENT_TYPES } from '../preset/fields/component-types'

type FieldLayoutProp = {
  [key: string]: string
} | null

const getFieldLayoutProp = (
  fieldConfigs: FieldsLayoutConfig,
  componentType: COMPONENT_TYPES,
  value: string
) => {
  const fieldConfig = fieldConfigs[componentType]

  if (fieldConfig) {
    return (!!!fieldConfig.supportedValues || fieldConfig.supportedValues.includes(value)) ? { [fieldConfig.key]: value } : null
  }

  return null
}

export type FieldPropHandler = (componentType: COMPONENT_TYPES, value: string) => FieldLayoutProp | null
export type FieldDataHandler = FieldPropHandler

export const getTextAlignmentProp: FieldPropHandler = (componentType, value) => {
  return getFieldLayoutProp(TEXT_ALIGNMENT, componentType, value)
}

export const getLabelMarginProp: FieldPropHandler = (componentType, value) => {
  return getFieldLayoutProp(LABEL_MARGIN, componentType, value)
}

export const getLabelPaddingProp: FieldPropHandler = (componentType, value) => {
  return getFieldLayoutProp(LABEL_PADDING, componentType, value)
}

export const getInputTextPaddingProp: FieldPropHandler = (componentType, value) => {
  return getFieldLayoutProp(TEXT_PADDING, componentType, value)
}
