import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalUrlData } from '../../general-fields/definitions/general-url'

const makeAdiURL = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalUrlData.make(), {
    extraData: {
      data: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_URL}.placeholder`),
        label: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_URL}.label`),
      },
      props: { placeholder: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_URL}.placeholder`) },
    },
  })
}

export const adiURLData: FieldData = {
  ...generalUrlData,
  make: makeAdiURL,
}
