import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalUploadButtonData } from '../../general-fields/definitions/general-upload-button'

const makeAdiUploadButton = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalUploadButtonData.make(), {
    extraData: {
      data: {
        label: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_UPLOAD_BUTTON}.label`),
        buttonLabel: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_UPLOAD_BUTTON}.placeholder`),
      },
    },
  })
}

export const adiGeneralUploadButton: FieldData = {
  ...generalUploadButtonData,
  make: makeAdiUploadButton,
}
