import { FormsFieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export const makeGeneralCheckboxOption = () => ({
  type: 'CheckboxInput',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  label: '',
  checked: true,
})

const makeGeneralCheckbox = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.TEXT,
    componentType: COMPONENT_TYPES.CHECKBOX_GROUP,
    extraData: {
      role: FIELDS.ROLE_FIELD_CHECKBOX_GROUP,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_CHECKBOX}`),
      },
      props: {
        placeholder: t(DEFAULT_PLACEHOLDER),
      },
      data: {
        placeholder: t(DEFAULT_PLACEHOLDER),
        label: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}Label`),
        options: [
          {
            ...makeGeneralCheckboxOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}FirstOptionsValue`),
            label: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}FirstOptionsLabel`),
          },
          {
            ...makeGeneralCheckboxOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}SecondOptionsValue`),
            label: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}SecondOptionsLabel`),
          },
          {
            ...makeGeneralCheckboxOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}ThirdOptionsValue`),
            label: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}ThirdOptionsLabel`),
            checked: false,
          },
          {
            ...makeGeneralCheckboxOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}ForthOptionsValue`),
            label: t(`preset.${FormsFieldPreset.GENERAL_CHECKBOX}ForthOptionsLabel`),
            checked: false,
          },
        ],
      },
    },
  }
}

export const generalCheckboxData: FieldData = {
  make: makeGeneralCheckbox,
  customFields: [customTypes.TEXT],
  icon: iconNames.multiSelectCheckbox,
  titleTranslationKey: `adi.${FormsFieldPreset.GENERAL_CHECKBOX}FieldEditPanel.title`
}
