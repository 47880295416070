import { createPanelDefs } from '../core/services/panel-definitions'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { EVENTS, ORIGINS, PANEL_NAMES } from '../../constants/bi'
import * as Raven from 'raven-js'
import { GFPP, GFPP_IDS } from '../core/manifests/manifest-commons'
import { getApi } from '../editor-ready/editor-ready'
import { openComponentPanel, openManageSubscribersPanel, openHelpPanel } from '../core-actions'
import { getBiLogger, getSiteId, setBiLogger, setPanelDefinitions } from '../editor-app-impl'
import { PanelName } from '../core/manage-panels/consts/panel-names'
import { ComponentRef } from '../core/api-types'
import { getPrimaryConnection } from '../core/utils'

const handleGfppClickedImpl = ({
  componentRef,
  id,
}: {
  componentRef: ComponentRef
  id: GFPP_IDS
}) => {
  const biLogger = getBiLogger()
  switch (id) {
    case GFPP_IDS.FORM_SETTINGS:
      openComponentPanel(componentRef, PanelName.FORM_SETTINGS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.NEW_FORM_SETTINGS:
      openComponentPanel(componentRef, PanelName.NEW_FORM_SETTINGS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.MANAGE_FIELDS:
      openComponentPanel(componentRef, PanelName.FORM_MANAGE_FIELDS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.manageFieldsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.ADD_FIELD:
      openComponentPanel(componentRef, PanelName.ADD_FIELD, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.MANAGE_SUBSCRIBERS:
      openManageSubscribersPanel()
      break
    case GFPP_IDS.FIELD_SETTINGS:
      openComponentPanel(
        componentRef,
        PanelName.FIELD_SETTINGS,
        ({ fieldType, crmLabel, formId }) =>
          biLogger.log({
            evid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL,
            field_type: fieldType,
            field_name: crmLabel,
            origin: ORIGINS.GFPP,
            form_comp_id: formId,
          })
      )
      break
    case GFPP_IDS.FORM_STYLE:
      openComponentPanel(componentRef, PanelName.FORM_STYLE)
      break
    case GFPP_IDS.FORM_LAYOUT:
      openComponentPanel(componentRef, PanelName.FORM_LAYOUT, ({ columns, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
          action: 'open',
          layout: columns === 1 ? 'single' : columns ? `${columns} column` : '2 column',
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.SUBMIT_SETTINGS:
      openComponentPanel(componentRef, PanelName.SUBMIT_SETTINGS)
      break
    case GFPP_IDS.RECAPTCHA_HELP:
      openHelpPanel(GFPP.HELP_ID.CAPTCHA)
      break
    default:
      break
  }
}

export const handleAppWidgetGfppClicked = async ({
  componentRef,
  id,
}: {
  componentRef: ComponentRef
  id: GFPP_IDS
}) => {
  const api = await getApi()
  const formContainer = await api.getFormContainerOfAppWidget(componentRef)
  return handleGfppClickedImpl({ componentRef: formContainer, id })
}

export const handleGfppClicked = ({
  componentRef,
  id,
}: {
  componentRef: ComponentRef
  id: GFPP_IDS
}) => handleGfppClickedImpl({ componentRef, id })

export const handleComponentDelete = async ({ componentRef, connections, historySnapshotId }) => {
  const api = await getApi()
  const connection = getPrimaryConnection(connections)
  api.handleDelete(componentRef, connection, historySnapshotId)
}

export const handlePublish = async () => {
  const api = await getApi()
  await api.sendAllFormsData()
}

export const handleFirstSave = async ({ metaSiteId: newMsid }) => {
  const api = await getApi()
  if (newMsid) {
    Raven.setUserContext({ id: `msid_${newMsid}` })
    const panelDefinitions = createPanelDefs(newMsid)
    const biLogger = await initBiLoggerForEditorApp(newMsid)
    setBiLogger(biLogger)
    setPanelDefinitions(panelDefinitions)
    api.setBiLogger(biLogger)
  }
  const siteId = await getSiteId()
  await api.reportBiFirstSave(siteId)
}

export const handlePresetChanged = async ({ componentRef, preset }) => {
  const api = await getApi()
  const esi = await api.getEditorSessionId()
  const biData = {
    startBi: {
      form_comp_id: componentRef.id,
      panel_name: PANEL_NAMES.formStylePanel,
      control_name: 'main',
      value: preset,
      esi,
    },
  }
  api.style.updateTheme(componentRef, preset, biData)
}

export const handleAppWidgetPasted = async ({ componentRef }) => {
  const api = await getApi()
  return api.reportBiAppWidgetPasted(componentRef)
}
