import { FIELDS } from '../../../../../../constants/roles'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { checkboxAgreeTermsData } from './checkbox-agree-terms'
import * as _ from 'lodash'

const makePrivacyPolicy = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge({}, checkboxAgreeTermsData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${RegistrationFieldPreset.REGISTRATION_FORM_PRIVACY_POLICY}`),
      },
      data: {
        label: t(`preset.${_.camelCase(RegistrationFieldPreset.REGISTRATION_FORM_PRIVACY_POLICY)}Label`),
      },
    },
  })
}

export const privacyPolicyData: FieldData = _.merge({}, checkboxAgreeTermsData, {
  make: makePrivacyPolicy,
})
