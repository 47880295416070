import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalNumberData } from '../../general-fields/definitions/general-number'

const makeAdiNumber = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalNumberData.make(), {
    extraData: {
      data: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_NUMBER}.placeholder`),
        label: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_NUMBER}.label`),
      },
      props: { placeholder: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_NUMBER}.placeholder`) },
    },
  })
}

export const adiNumberData: FieldData = {
  ...generalNumberData,
  make: makeAdiNumber,
}
