import * as _ from 'lodash'
import { createForm } from '../services/form-service'
import { createSuffixedName } from '../../../utils/utils'
import { SHOULD_CREATE_COLLECTION } from '../preset/presets-data'
import translations from '../services/translations'
import { undoable } from '../utils'
import { FormPreset } from '../../../constants/form-types'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormSnapshot } from '../api-types'
import { ROLE_FORM } from '../../../constants/roles'
import CollectionsApi from '../collections/api'
import CoreApi from '../core-api'
import { APP_CONTROLLER_DEFINITION, APP_WIDGET_DEFINITION } from './controller-definition'
import RemoteApi from '../../../panels/commons/remote-api'
import { ADI_INITIATOR, MASTER_PAGE, TEMPLATE_ADI_INITIATOR } from './constants'
import { FormPlugin } from '../../../constants/plugins'

const normalizeFormName = (
  formNames: string[],
  nameFromPreset: string | undefined,
  presetKey: FormPreset
) => {
  const title =
    nameFromPreset ||
    translations.t('formName', {
      name: translations.t(`addForm.templates.${presetKey}.label`),
    })

  return createSuffixedName(formNames, title)
}

const isADI = initiator => _.isEmpty(initiator) || initiator === ADI_INITIATOR
const isTemplateADI = initiator => initiator === TEMPLATE_ADI_INITIATOR
const addedFormsPromisesContainer = {}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private collectionsApi: CollectionsApi
  private remoteApi: RemoteApi
  private experiments: any

  constructor(boundEditorSDK, coreApi, collectionsApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.collectionsApi = collectionsApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  @undoable()
  public async addForm(
    presetKey: FormPreset,
    { containerRef = null, targetPageRef = null, coords = null, source_name = null } = {},
    formSnapshot?: FormSnapshot
  ) {
    if (!containerRef) {
      await this.coreApi.saveSiteIfUnsaved()
    }

    const locale = await this.boundEditorSDK.info.getLanguage()
    const { plugins, box, fields } = await Promise.resolve(
      createForm(
        presetKey,
        {
          coords,
          locale,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason),
        formSnapshot
      )
    )

    if (_.find(plugins, { id: FormPlugin.GET_SUBSCRIBERS })) {
      this.coreApi.managePanels.openGetSubscribersFirstTimePanel()
    }

    const [pageRef, msid] = await Promise.all([
      targetPageRef || this.boundEditorSDK.pages.getCurrent(),
      this.coreApi.getMetaSiteId(),
    ])

    if (formSnapshot) {
      return this._addFromSnapshot(box, fields, pageRef, containerRef)
    }

    const formName = await this._getFormName(_.get(box, 'connectionConfig.formName'), presetKey)
    let formLabelId = ''

    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}

    let emailId = ''

    try {
      emailId = await this.coreApi.getOwnerEmailId()
    } catch (err) {}

    const containerDefinition = _.merge({}, box, {
      connectionConfig: {
        formName,
        msid,
        formLabelId,
        emailId,
        labels: [...box.connectionConfig.labels, formLabelId],
      },
    })

    let controllerRef
    const initiator = this.coreApi.initiator()
    const isAppWidgetEnabled =
      this.experiments.enabled('specs.cx.FormBuilderAppWidget') &&
      !isADI(initiator) &&
      !isTemplateADI(initiator)
    if (isAppWidgetEnabled) {
      let containerOfForm
      if (containerRef) {
        const containerLayout = await this.boundEditorSDK.components.layout.get({
          componentRef: containerRef,
        })
        _.merge(containerDefinition.data.layout, _.pick(containerLayout, ['x', 'y']))

        const ancestors = await this.boundEditorSDK.components.getAncestors({
          componentRef: containerRef,
        })
        containerOfForm = _.first(ancestors)
      } else {
        containerOfForm = pageRef
      }

      controllerRef = await this._addAppWidget(containerOfForm, containerDefinition)
    } else {
      controllerRef = await this._addController(pageRef)
    }
    this.coreApi.appState.setState([controllerRef])

    let formRef
    if (containerRef && !isAppWidgetEnabled) {
      await this.coreApi.connect(containerDefinition, controllerRef, containerRef)

      this._removeLoader(containerRef)

      const {
        data: {
          layout: { width, height },
        },
      } = box

      if (box.connectionConfig.preset === FormPreset.REGISTRATION_FORM) {
        await this.coreApi.layout.centerComponentInsideLightbox(containerRef)
      } else {
        this.boundEditorSDK.components.layout.update({
          componentRef: containerRef,
          layout: { height, width },
        })
      }
      formRef = containerRef
    } else {
      if (isAppWidgetEnabled) {
        const components = await this.boundEditorSDK.controllers.getControllerConnections({
          controllerRef,
        })
        formRef = components[0].componentRef
        if (!containerDefinition.data.layout.y) {
          await this._fixFormY(controllerRef)
        }
      } else {
        formRef = (await this.coreApi.addComponentAndConnect(
          containerDefinition,
          controllerRef,
          pageRef
        )).connectToRef
        await this._fixFormY(formRef)
      }
    }

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.CHOOSE_TEMPLATE,
      template: presetKey,
      form_comp_id: formRef.id,
      source_name: source_name || initiator,
    })

    let customFields

    if (isADI(initiator)) {
      customFields = await this.coreApi.fields.fetchCustomFieldsByName()
    }

    const fieldsData = await Promise.all(
      fields.map(async field => {
        const fieldName = field.connectionConfig.crmLabel
        const fieldData = _.merge({}, field, {
          connectionConfig: {
            collectionFieldKey: _.camelCase(fieldName),
          },
        })

        if (isADI(initiator)) {
          const customFieldId = await this.coreApi.fields.getCustomFieldForField(
            customFields,
            fieldData
          )

          if (customFieldId) {
            fieldData.connectionConfig.customFieldId = customFieldId
          }
        }

        const { role, connectionConfig } = await this.coreApi.addComponentAndConnect(
          fieldData,
          controllerRef,
          formRef
        )
        return _.merge({ role }, connectionConfig)
      })
    )

    if (!_.find(plugins, { id: FormPlugin.REGISTRATION_FORM })) {
      this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [isAppWidgetEnabled ? controllerRef : formRef],
      })
    }

    if (containerRef && isAppWidgetEnabled) {
      this.boundEditorSDK.components.remove({ componentRef: containerRef })
    }

    addedFormsPromisesContainer[formRef.id] = new Promise(async resolve => {
      if (!containerRef) {
        this._createAutoCollection(formRef, presetKey, fieldsData).then(async collectionId => {
          await Promise.all([
            this.coreApi.editDraft(formRef, formName, collectionId),
            this.coreApi.saveSite(),
          ])
          resolve()
        })
      } else {
        await this.coreApi.editDraft(formRef, formName)
        resolve()
      }
    })

    return formRef
  }

  public waitForAddedForm(formCompRef: ComponentRef): Promise<undefined> {
    return addedFormsPromisesContainer[formCompRef.id]
  }

  private async _addFromSnapshot(formDefinition, fields, pageRef, formRef: ComponentRef) {
    const controllerRef = await this._addController(pageRef)
    this.coreApi.appState.setState([controllerRef])
    formDefinition.connectionConfig.collectionId = this._getOldCollectionId(formDefinition, formRef)

    await this.coreApi.connect(formDefinition, controllerRef, formRef)

    const {
      data: {
        layout: { width, height },
      },
    } = formDefinition
    await this.boundEditorSDK.components.layout.update({
      componentRef: formRef,
      layout: { height, width },
    })

    await Promise.all(
      fields.map(field => this.coreApi.addComponentAndConnect(field, controllerRef, formRef))
    )

    this.coreApi.editDraft(formRef, formDefinition.connectionConfig.formName)
    return formRef
  }

  private _getOldCollectionId(formDefinition, formRef: ComponentRef) {
    if (!_.get(formDefinition, 'connectionConfig.collectionId')) return
    const compIdAndRealCollectionId = formDefinition.connectionConfig.collectionId.split('_')
    if (compIdAndRealCollectionId.length > 1) {
      const realCollectionId = compIdAndRealCollectionId[1]
      return `${formRef.id}_${realCollectionId}`
    }
    return formDefinition.connectionConfig.collectionId
  }

  private async _addController(pageRef) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const pageData = await this.boundEditorSDK.components.data.get({
      componentRef: pageRef,
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_CONTROLLER_DEFINITION, {
        data: {
          applicationId: appDefinitionId,
        },
      }),
      pageRef: _.get(pageData, 'isPopup') ? pageRef : MASTER_PAGE,
    })
  }

  private async _addAppWidget(pageRef, containerDefinition) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const dataItemIdPlaceholder = 'data_item_id_placeholder'

    const container = _.merge({}, containerDefinition.data, {
      components: [],
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            role: ROLE_FORM,
            controllerId: dataItemIdPlaceholder,
            isPrimary: true,
            config: JSON.stringify(containerDefinition.connectionConfig),
          },
        ],
      },
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_WIDGET_DEFINITION, {
        data: {
          applicationId: appDefinitionId,
          id: dataItemIdPlaceholder,
        },
        layout: containerDefinition.data.layout,
        components: [container],
      }),
      pageRef,
    })
  }

  private async _fixFormY(componentRef: ComponentRef): Promise<void> {
    const { height, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })
    this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { y: _.max([0, y - height / 2]) },
    })
  }

  private async _createAutoCollection(
    componentRef: ComponentRef,
    presetKey: FormPreset,
    fieldsData,
    shouldCreateCollection = false
  ): Promise<string | null> {
    shouldCreateCollection = shouldCreateCollection || SHOULD_CREATE_COLLECTION[presetKey]

    if (!shouldCreateCollection) {
      return Promise.resolve(null)
    }

    const startCollectionBi = {
      template: presetKey,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }
    const endCollectionBi = {
      template: presetKey,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }

    const collectionId = await this.collectionsApi.createCollection(
      { preset: presetKey },
      { startBi: startCollectionBi, endBi: endCollectionBi }
    )
    if (!collectionId) {
      return null
    }

    return Promise.all([
      this.coreApi.setComponentConnection(componentRef, {
        collectionId: `${componentRef.id}_${collectionId}`,
      }),
      this.collectionsApi.addFieldsToCollection(
        collectionId,
        fieldsData,
        (fieldComponent, fieldKey) =>
          this.coreApi.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
      ),
    ]).then(() => collectionId)
  }

  private async _getFormName(nameFromPreset, presetKey): Promise<string> {
    const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formNames = await Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
        const {
          config: { formName },
        } = formRef
          ? await this.coreApi.getComponentConnection(formRef)
          : { config: { formName: '' } }
        return formName
      })
    )

    return normalizeFormName(formNames, nameFromPreset, presetKey)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }

  private async _removeLoader(componentRef): Promise<void> {
    return this.boundEditorSDK.components.style.update({
      componentRef: componentRef,
      style: { loader: 0 },
    })
  }

  public async createAutoCollection(componentRef: ComponentRef) {
    const fieldData = [
      'collectionFieldKey',
      'crmLabel',
      'crmTag',
      'crmType',
      'fieldType',
      'label',
      'role',
    ]
    const {
      config: { preset, collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    if (collectionId) {
      return collectionId
    }
    const rawFieldsData = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })
    const fieldsData = rawFieldsData.map(field => _.pick(field, fieldData))

    return this._createAutoCollection(componentRef, preset, fieldsData, true)
  }
}
