import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalTextBoxData } from '../../general-fields/definitions/general-text-box'

const makeAdiGeneralTextBox = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalTextBoxData.make(), {
    extraData: {
      props: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_TEXT_BOX}.placeholder`),
      },
      data: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_TEXT_BOX}.placeholder`),
        label: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_TEXT_BOX}.label`),
      },
    },
  })
}

export const adiGeneralTextBoxData: FieldData = {
  ...generalTextBoxData,
  make: makeAdiGeneralTextBox,
}
