import * as _ from 'lodash'
import { bindObjectFunctions } from '../../utils/utils'
import translations from '../core/services/translations'
import Experiments from '@wix/wix-experiments'
import * as createWixCodeCollectionsApi from '@wix/wix-code-collections-api'
import * as createWixCodeCollectionPermissionsApi from '@wix/wix-code-collections-permissions-api'
import * as createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { ORIGINS } from '../../constants/bi'
import * as Raven from 'raven-js'
import RemoteApi from '../../panels/commons/remote-api'
import { FormPreset } from '../../constants/form-types'
import CoreApi from '../core/core-api'
import { createPanelDefs } from '../core/services/panel-definitions'
import { setBiLogger, setPanelDefinitions } from '../editor-app-impl'
import { addForm, openAddFormPanel } from '../core-actions'
import { fetcher } from '../utils/utils'
import { fetchAllThemes } from '../core/preset/themes-service'

const EDITOR_INITIATOR = 'EDITOR'

const fetchCoreApi = fetcher()
export const getApi = () => fetchCoreApi.getData // race-condition editorReady & onEvent

export const startApp = async (withPreset, origin) => {
  if (!origin) {
    return
  }
  const api: CoreApi = await getApi()
  api.updateOrigin(origin)

  if (withPreset) {
    await addForm(origin.info.preset, {
      containerRef: origin.info.containerRef,
      coords: origin.info.coords,
      theme: origin.info.theme,
      source_name: ORIGINS.ADD_PANEL,
    })
    return api.saveSite()
  } else if (_.get(origin, 'info.type') === FormPreset.REGISTRATION_FORM) {
    addForm(origin.info.preset || FormPreset.REGISTRATION_FORM, {
      containerRef: origin.info.containerRef,
      source_name: ORIGINS.SIGNUP_SETTINGS,
    })
  } else if (origin.initiator === EDITOR_INITIATOR) {
    return openAddFormPanel()
  }
}

export const initApp = async ({ appDefinitionId, editorSDK, msid, origin }) => {
  const { coreApi, boundEditorSDK, biLogger, experiments } = await initCoreApi({
    appDefinitionId,
    editorSDK,
    msid,
    origin,
  })
  const [locale] = await Promise.all([
    boundEditorSDK.environment.getLocale(),
    boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi()),
  ])
  await experiments.load('wix-form-builder')
  await translations.editorInit(locale)

  setBiLogger(biLogger)

  coreApi.appState.setState()
  fetchCoreApi.resolveData(coreApi)

  const panelDefinitions = createPanelDefs(
    msid
  )
  setPanelDefinitions(panelDefinitions)

  fetchAllThemes()
}

const initCoreApi = async ({ appDefinitionId, editorSDK, msid, origin }) => {
  const boundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const experiments = new Experiments({ baseUrl: 'https://www.wix.com' })
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  const collectionsApi = createWixCodeCollectionsApi({
    transportLayer,
    pluginId: appDefinitionId,
  })
  const collectionsPermissionsApi = createWixCodeCollectionPermissionsApi({
    editorSDK,
    token: appDefinitionId,
  })

  const remoteApi = new RemoteApi({ boundEditorSDK, experiments })
  const biLogger = await initBiLoggerForEditorApp(msid, origin)

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      collectionsPermissionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance: Raven,
    biLogger,
    origin,
  })
  return { coreApi, boundEditorSDK, biLogger, experiments }
}
