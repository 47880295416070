import webBiLogger from '@wix/web-bi-logger'
import { BI } from '../constants/bi'
import { sanitizePII } from '@wix/bi-logger-sanitizer'
import * as _ from 'lodash'

const sanitizeBiLogger = (fields = {}) => {
  const sanitizedFields = {}

  Object.keys(fields).forEach(fieldName => {
    sanitizedFields[fieldName] = sanitizePII(fields[fieldName])
  })

  return sanitizedFields
}

export const initBiLogger = ({ endpoint = BI.ENDPOINT, src = BI.SRC, defaults = {} } = {}) => {
  const biLogger = webBiLogger
    .factory({ endpoint })
    .setDefaults({ src, ...defaults })
    .logger()
  return {
    log: fields => {
      const sanitizedFields = sanitizeBiLogger(fields)
      return biLogger.log(sanitizedFields)
    },
  }
}

export const initBiLoggerForEditorApp = async (msid, origin = {}) => {
  return initBiLogger({ defaults: { msid, builder_origin: _.get(origin, 'initiator', 'Editor') } })
}
