import 'isomorphic-fetch'
import * as Raven from 'raven-js'

const MAX_FETCH_RETRIES = 3
const DELAY_AFTER_FETCH_RETRY = 100
const HTTP_STATUS_OK = 200
const IMMUTABLES_ERROR_CODES = [400] // skip retry for user input errors

const shouldRetry = (retries, status = -1) =>
  retries > 0 && !IMMUTABLES_ERROR_CODES.includes(status)

export const fetchRetry = (url?: Request | string, options?: RequestInit): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const wrappedFetch = retries => {
      fetch(url, options)
        .then(response => {
          if (response.status === HTTP_STATUS_OK) { //tslint:disable-line
            resolve(response)
          } else {
            if (shouldRetry(retries, response.status)) {
              retry(retries)
            } else {
              const requestId = response.headers.get('x-wix-request-id')
              if (requestId) {
                Raven.setTagsContext({ 'x-wix-request-id': requestId })
              }

              const headersObject = {}
              response.headers.forEach((value, key) => {
                headersObject[key] = value
              })

              Raven.setExtraContext(headersObject)

              response
                .json()
                .then(err => {
                  Raven.setExtraContext(err)
                  reject(new Error(`Fetch failed with status ${response.status}`))
                })
                .catch(() => {
                  reject(new Error(`Fetch failed with status ${response.status}`))
                })
            }
          }
        })
        .catch(error => {
          if (shouldRetry(retries)) {
            retry(retries)
          } else {
            reject(error)
          }
        })
    }

    const retry = retries => {
      setTimeout(() => {
        wrappedFetch(--retries)
      }, DELAY_AFTER_FETCH_RETRY)
    }

    wrappedFetch(MAX_FETCH_RETRIES)
  })
}
