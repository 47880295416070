import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalSingleCheckboxData } from './general-single-checkbox'
import { iconNames } from '../../icons/types'

const makeGeneralSubscribe = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalSingleCheckboxData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_SUBSCRIBE,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_SUBSCRIBE}`),
      },
      data: {
        label: t(`preset.${FormsFieldPreset.GENERAL_SUBSCRIBE}Label`),
      },
      layout: {
        width: 290,
      },
    },
  })
}

export const generalSubscribeData: FieldData = {
  make: makeGeneralSubscribe,
  customFields: generalSingleCheckboxData.customFields,
  icon: iconNames.subscribe,
  allowOnlyOnce: true
}
